export default {
  screens: {
    // max-widths in pixels
    sm: 640,
    md: 768,
    lg: 1024,
    xl: 1280,
  },
  colors: {
    headerBackground: "rgb(8,8,23)",
    nivodentGreen: "rgb(32, 104, 90)",
    offWhite: "#eee",
    offBlack: "#333",
    detail: "rgb(155, 81, 80)",
    footerBackground: "rgb(8,8,23)",
  },
  opacity: {
    0: 0,
    25: 0.25,
    50: 0.5,
    75: 0.75,
    100: 1,
  },
  py: (value: number | string): string =>
    `padding-top: ${value}; padding-bottom: ${value};`,
  // Add anything else you'd like.
};
