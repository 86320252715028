import { createGlobalStyle } from "styled-components";

const GlobalStyles = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    color: ${({ theme }) => theme.colors.offBlack};
    font-family: "Montserrat", Helvetica, Arial, sans-serif;
  }

  h1, h2, h3, h4 {
    font-weight: 300;
  }

  h2 {
    font-size: 1.2rem;
  }

  a {
    text-decoration: none;
    font-weight: bold;
    color: ${({ theme }) => theme.colors.nivodentGreen};

    &:hover {
      color: ${({ theme }) => theme.colors.offBlack};
    }
  }
`;

export { GlobalStyles };
