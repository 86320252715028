import { config, dom } from "@fortawesome/fontawesome-svg-core";
import React from "react";
import { ThemeProvider } from "styled-components";
import { GlobalStyles, theme } from "../styles";
import Footer from "./footer";

config.autoAddCss = false;

export default ({
  children,
}: {
  children: React.ReactNode;
}): React.ReactElement => (
  <>
    <style type="text/css">{dom.css()}</style>
    <ThemeProvider theme={theme()}>
      <GlobalStyles />
      <main>{children}</main>
      <Footer />
    </ThemeProvider>
  </>
);
