import React from "react";
import styled from "styled-components";

const StyledFooter = styled.footer`
  width: 100%;
  background: ${({ theme }) => theme.colors.footerBackground};
  min-height: 100px;
  padding: 20px;
  color: ${({ theme }) => theme.colors.offWhite};
  text-align: right;
`;

export default (): React.ReactElement => {
  return <StyledFooter>© 2020 NivoDent</StyledFooter>;
};
